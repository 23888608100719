.pfdb_main {
    margin: 0.5em;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.pfdb_spells_section{
    border: 1px solid black;
    border-radius: 1em;
    padding: 0.5em;
    box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.2);
    margin-right: 0.5em;
    text-align: center;
}
.pfdb_spells_section .image_container{
    text-align: center;
}

.pfdb_spellbook {

}