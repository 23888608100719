.statblock {
    border: 1px solid black;
    text-align: left;
    padding: 1em;
    font: normal 13px 'Lato', sans-serif;
}

.statblock .sectionHeader {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0.1em 0;
    text-transform: uppercase;
    font-size: 16px;
}